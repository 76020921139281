import logo from '../images/logo-caixa-azul.svg';
document.getElementById('brand').src = logo;

document.getElementById('show-password').addEventListener('click', showPassword)

function showPassword(event) {
    event.preventDefault();
    var x = document.getElementById("password");
    var y = document.getElementById("change_icon");

    if (x.type === "password") {
        x.type = "text";
        y.classList.replace("fa-eye","fa-eye-slash");
    } else {
        x.type = "password";
        y.classList.replace("fa-eye-slash","fa-eye");
    }
}